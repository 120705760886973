// @flow

import { BOOTSTRAP } from '../actions/bootstrap';
import { UPDATE_BID_NEW } from '../actions/bidNew';
import { FETCH_BID_QUOTE, FETCH_BID_QUOTE_SUCCESS, RESET_BID_QUOTE } from '../actions/shippingQuoteBid';
import type { Actions as BootstrapActions } from '../actions/bootstrap';
import type { Actions as BidNewActions } from '../actions/bidNew';
import type { Actions as ShippingQuoteBidActions } from '../actions/shippingQuoteBid';
import type { Bid } from '../types';

type Actions = BootstrapActions | BidNewActions | ShippingQuoteBidActions;

const initialState = {
  id: null,
  amount: null,
  max: null,
  fulfillmentType: null,
  isConfirmed: false,
  errors: null,
  amountErrors: [],
  maxErrors: [],
  confirmationError: false,
  paymentMethod: null,
  hasPaymentDiscount: false,
  address: null,
  shippingQuote: null,
  bypassGiftCardBalance: false,
};

const bidReducer = (state: Bid = initialState, action: Actions): Bid => {
  switch (action.type) {
    case BOOTSTRAP:
    case UPDATE_BID_NEW:
      return {
        ...state,
        ...action.bid,
      };
    case FETCH_BID_QUOTE:
      return {
        ...state,
        shippingQuote: null,
      };
    case FETCH_BID_QUOTE_SUCCESS:
      return {
        ...state,
        shippingQuote: action.shippingQuote,
      };
    case RESET_BID_QUOTE:
      return {
        ...state,
        shippingQuote: null,
      };
    default:
      return state;
  }
};

export default bidReducer;
