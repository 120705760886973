// @flow

import { BOOTSTRAP } from '../actions/bootstrap';
import { UPDATE_BID } from '../actions/bid';

import type { Actions as BootstrapActions } from '../actions/bootstrap';
import type { Actions as BidActions } from '../actions/bid';
import type { Bid } from '../types';

type Actions = BootstrapActions | BidActions;

const initialState = {
  id: null,
  amount: null,
  max: null,
  fulfillmentType: null,
  isConfirmed: false,
  errors: null,
  amountErrors: [],
  maxErrors: [],
  confirmationError: false,
  paymentMethod: null,
  hasPaymentDiscount: false,
  address: null,
  shippingQuote: null,
  bypassGiftCardBalance: false,
};

const bidReducer = (state: Bid = initialState, action: Actions): Bid => {
  switch (action.type) {
    case BOOTSTRAP:
    case UPDATE_BID:
      return {
        ...state,
        ...action.bid,
      };

    default:
      return state;
  }
};

export default bidReducer;
