import update from 'immutability-helper';
import { get } from 'lodash';
import transformErrorsToChanges from '~/utils/transformErrorsToChanges';
import PaymentMethodHelper from '~/public/shared/utils/PaymentMethodHelper';

function initialize(user = {}) {
  // Only allows first and last name to be prepopulated for now
  const defaults = {
    firstName: '',
    lastName: '',
  };

  const values = Object.assign({}, defaults, user);

  return {
    base: {
      errors: [],
    },
    firstName: {
      value: values.firstName || '',
      errors: [],
    },
    lastName: {
      value: values.lastName || '',
      errors: [],
    },
    phone: {
      value: '',
      errors: [],
    },
    smsOptin: {
      value: false,
      errors: [],
    },
    paymentMethod: PaymentMethodHelper.initialize(),
  };
}

function validate(registration) {
  const errors = {};
  let hasErrors = false;

  if (registration.firstName.value.length > 0) {
    errors.firstName = { errors: { $set: [] } };
  } else {
    hasErrors = true;
    errors.firstName = { errors: { $set: ['First name required'] } };
  }

  if (registration.lastName.value.length > 0) {
    errors.lastName = { errors: { $set: [] } };
  } else {
    hasErrors = true;
    errors.lastName = { errors: { $set: ['Last name required'] } };
  }

  if (registration.smsOptin.value === true) {
    if (registration.phone.value.length === 0) {
      hasErrors = true;
      errors.phone = { errors: { $set: ['Phone number is required to receive alerts'] } };
    } else {
      errors.phone = { errors: { $set: [] } };
    }
  } else {
    errors.phone = { errors: { $set: [] } };
  }

  const updatedPaymentMethod = PaymentMethodHelper.validate(registration.paymentMethod);
  if (updatedPaymentMethod.hasErrors === true) {
    hasErrors = true;
  }
  errors.paymentMethod = { $set: updatedPaymentMethod };

  errors.hasErrors = { $set: hasErrors };

  return update(registration, errors);
}

function parseErrorsFromRails(errors) {
  const getError = (key) => get(errors, key, []);

  return {
    firstName: getError('first_name'),
    lastName: getError('last_name'),
    phone: getError('mobile_phone'),
    smsOptin: getError('sms_optin'),
    paymentMethod: {
      name: getError('payment_method.name_on_card'),
      expMonth: getError('payment_methods.exp_month'),
      expYear: getError('payment_methods.exp_year'),
      address: {
        id: getError('payment_methods.address.id'),
        name: getError('payment_methods.address.name'),
        line1: getError('payment_methods.address.address'),
        line2: getError('payment_methods.address.floor_suite'),
        city: getError('payment_methods.address.city'),
        state: getError('payment_methods.address.state'),
        country: getError('payment_methods.address.country'),
        zip: getError('payment_methods.address.zip'),
      },
    },
  };
}

function applyServerErrors(registration, serverErrors) {
  const changes = transformErrorsToChanges(parseErrorsFromRails(serverErrors));

  changes.hasErrors = { $set: true };

  if (serverErrors.base) {
    changes.base = { errors: { $set: serverErrors.base } };
    // Don't reuse token for a card that errored
    changes.paymentMethod.token = { value: { $set: '' } };
  }

  return update(registration, changes);
}


async function save(registration) {
  const getValue = (key, fallback = '') => {
    return get(registration, key, { value: fallback }).value;
  };

  try {
    const response = await fetch('/api/v1/buyer_registration', {
      method: 'put',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        first_name: getValue('firstName'),
        last_name: getValue('lastName'),
        mobile_phone: getValue('phone'),
        sms_optin: getValue('smsOptin', false),
        payment_method: {
          name_on_card: getValue('paymentMethod.name'),
          vendor_code: getValue('paymentMethod.token'),
          last4: getValue('paymentMethod.last4'),
          exp_month: getValue('paymentMethod.expMonth'),
          exp_year: getValue('paymentMethod.expYear'),
          primary: getValue('paymentMethod.primary'),
          address: {
            id: getValue('paymentMethod.address.id', null),
            name: getValue('paymentMethod.address.name'),
            address: getValue('paymentMethod.address.line1'),
            floor_suite: getValue('paymentMethod.address.line2'),
            city: getValue('paymentMethod.address.city'),
            state: getValue('paymentMethod.address.state'),
            zip: getValue('paymentMethod.address.zip'),
            country: getValue('paymentMethod.address.country'),
          },
        },
      }),
    });

    const responseBody = await response.json();

    if (!response.ok && response.status !== 401) {
      registration = applyServerErrors(registration, responseBody.errors);
    }
  } catch (_err) {
    // Communication error
    registration = update(registration, {
      hasErrors: { $set: true },
      base: { errors: { $set: ['Network error, please try again.'] } },
    });
  }

  return registration;
}

export default {
  initialize,
  validate,
  applyServerErrors,
  save,
};
