// @flow

import Cookies from 'js-cookie';

import type { ShippingQuote } from '../types';

export const FETCH_QUOTE = 'FETCH_QUOTE';
export type FetchQuoteAction = {
  type: 'FETCH_QUOTE',
};

export const FETCH_QUOTE_SUCCESS = 'FETCH_QUOTE_SUCCESS';
export type FetchQuoteSuccessAction = {
  type: 'FETCH_QUOTE_SUCCESS',
  shippingQuote: ShippingQuote,
};

export const FETCH_QUOTE_FAILURE = 'FETCH_QUOTE_FAILURE';
export type FetchQuoteFailureAction = {
  type: 'FETCH_QUOTE_FAILURE',
  error: string,
};

export const RESET_QUOTE = 'RESET_QUOTE';
export type ResetQuoteAction = {
  type: 'RESET_QUOTE',
};

export type Actions = (
  | FetchQuoteAction
  | FetchQuoteSuccessAction
  | FetchQuoteFailureAction
  | ResetQuoteAction
);

const zipPattern = /^\d{5}$/;
const invalidZip = (zip) => !zipPattern.test(zip);

export const fetchQuote = ({ itemId, zip }: { itemId: string, zip: string }): Thunk<void> => {
  return async (dispatch) => {
    if (invalidZip(zip)) {
      dispatch({
        type: FETCH_QUOTE_FAILURE,
        error: 'Enter a valid 5-digit zip code',
      });

      return;
    }

    Cookies.set('user_zip_code', zip, { expires: 365 });

    dispatch({
      type: FETCH_QUOTE,
    });

    try {
      const response = await fetch(`/api/v1/items/${itemId}/shipping_quotes?to=${zip}`);
      const json = await response.json();

      if (response.status === 200) {
        dispatch({
          type: FETCH_QUOTE_SUCCESS,
          shippingQuote: {
            id: json.id,
            quote: json.formatted_total,
            zip: json.zip,
          },
        });
      } else {
        dispatch({
          type: FETCH_QUOTE_FAILURE,
          error: 'Could not retrieve shipping quote',
        });
      }
    } catch (e) {
      console.error(e);
      dispatch({
        type: FETCH_QUOTE_FAILURE,
        error: 'Could not retrieve shipping quote',
      });
    }
  };
};

export const resetQuote = () => {
  return {
    type: 'RESET_QUOTE',
  };
};
